<div class="row">
  <div class="col-sm-12">
    <p>Since you created the game, you're the admin, and have some special powers!</p>
  </div>
</div>
<tabset>
  <tab heading="Kick Current Player" *ngIf="game.createdBySteamId !== game.currentPlayerSteamId">
    <div class="well">
      <pydt-game-detail-admin-kick-player
        [game]="game"
        (setGame)="setGame.emit($event)"
      ></pydt-game-detail-admin-kick-player>
    </div>
  </tab>
  <tab heading="Reset Game State on Next Upload">
    <div class="well">
      <pydt-game-detail-admin-reset-game
        [game]="game"
        (setGame)="setGame.emit($event)"
      ></pydt-game-detail-admin-reset-game>
    </div>
  </tab>
  <tab heading="Restart Game">
    <div class="well">
      <div class="row">
        <pydt-game-detail-admin-restart-game
          [game]="game"
          (setGame)="setGame.emit($event)"
        ></pydt-game-detail-admin-restart-game>
      </div>
    </div>
  </tab>
  <tab heading="Clone Game">
    <div class="well">
      <pydt-game-detail-admin-clone-game
        [game]="game"
        (setGame)="setGame.emit($event)"
      ></pydt-game-detail-admin-clone-game>
    </div>
  </tab>
  <tab heading="Mark Player for Substitution">
    <div class="well">
      <pydt-game-detail-admin-mark-substitution
        [game]="game"
        (setGame)="setGame.emit($event)"
      ></pydt-game-detail-admin-mark-substitution>
    </div>
  </tab>
  <tab heading="Manage Mods" *ngIf="game.gameType === 'CIV6'">
    <div class="well">
      <pydt-game-detail-manage-mods [game]="game" [admin]="true"></pydt-game-detail-manage-mods>
    </div>
  </tab>
</tabset>
