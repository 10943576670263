<div *ngIf="!!auth.getToken()" style="display: inline-block">
  <div class="btn-group" dropdown>
    <button
      id="button-basic"
      dropdownToggle
      type="button"
      class="btn btn-primary dropdown-toggle"
      aria-controls="dropdown-basic"
    >
      Create a New Game! <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
      <li role="menuitem" *ngFor="let game of games">
        <a class="dropdown-item" style="cursor: pointer" (click)="tryCreateGame(game)">{{ game.displayName }}</a>
      </li>
    </ul>
  </div>
</div>

<div
  bsModal
  #cannotCreateGameModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="cannotCreateGameModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="cannotCreateGameModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Cannot Create {{ selectedGame?.displayName }} Game</h4>
      </div>
      <div class="modal-body">
        {{ cannotCreateMessage }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="cannotCreateGameModal.hide()">OK</button>
      </div>
    </div>
  </div>
</div>
